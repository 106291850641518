.item {
    composes: mt-6 mb-8 ml-4 from global;
    position: relative;
    max-width: 272px;
    min-height: 208px;
}

.item_image {
    composes: pl-4 pr-4 mb-1 from global;
    cursor: pointer;
}

.item_price {
    composes: mt-1 from global;
    display: flex;
    justify-content: center;
}

.item_price_value {
    composes: text text_type_digits-default mr-1 from global;
}

.item_description {
    composes: mt-1 ml-1 mr-1 from global;
    display: flex;
    justify-content: center;
    text-align: center;
}

.item_description_text {
    composes: text text_type_main-default pt-1 from global;
}
