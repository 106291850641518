.modal {
    border-radius: 40px;
    width: 720px;
    min-height: 400px;
    position: absolute;
    background-color: #1C1C21;
    display: flex;
    flex-direction: column;
    position: relative;
}

.header {
    composes: ml-10 mr-10 mt-10 from global;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header_text {
    composes: text text_type_main-large from global;
    flex-grow: 1;
}

.close, .close_detached {
    cursor: pointer;
}

.close_detached {
    composes: mr-10 mt-15 from global;
    position: absolute;
    top: 0;
    right: 0;
}
