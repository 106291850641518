.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.list {
    flex-grow: 1;
    height: 0;
    overflow: scroll;
    overflow-x: hidden;

    list-style-type: none;
    margin: 0;
    padding: 0;
}

.list_item {
    composes: mb-4 pr-2 from global;
}
