.header {
    composes: text text_type_main-medium from global;
    text-align: center;
}

.field {
    composes: mt-6 from global;
}

.error_pane {
    display: flex;
    justify-content: center;
}

.error {
    composes: text text_type_main-default mt-6 from global;
    color: red;
}

.button {
    composes: mt-6 mb-20 from global;
    display: flex;
    justify-content: center;
}

.sub {
    composes: mb-4 text text_type_main-default text_color_inactive from global;
    text-align: center;
}
