.item {
    height: 64px;
    composes: mb-4 mr-4 from global;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.item_text {
    flex-grow: 1;
    composes: ml-4 mr-4 from global;
}

.text {
    composes: text text_type_main-default from global;
}
