.app {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.main {
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
