.nav {
    display: flex;
    margin: auto;
    align-items: center;
    width: 1248px;
}

.nav > * {
    flex: 1;
}

.menu_group {
    display: flex;
    align-items: center;
}

.personal {
    display: flex;
    justify-content: flex-end;
}

.anchor {
    composes: p-5 mt-4 mb-4 from global;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.anchor_text {
    composes: text text_type_main-default text_color_inactive pl-2 from global;
}

.anchor_text_active {
    composes: text text_type_main-default pl-2 from global;
}
