.ingredients {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    flex-grow: 0;

    composes: text text_type_main-large pt-10 pb-5 from global;
}

.tabs {
    flex-grow: 0;

    display: flex;
    flex-direction: row;
}

.sections {
    flex-grow: 1;
    
    height: 0;
    overflow: scroll;
    overflow-x: hidden;
}

.section_header {
    composes: text text_type_main-medium pt-10 from global;
}

.section_container {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    padding: 0;
}
