.ingredient, .more {
    border-radius: 32px;
    width: 64px;
    height: 64px;
    border: 2px solid transparent;
    box-sizing: border-box;
}

.ingredient {
    position: relative; 
    
    background-image: linear-gradient(#131316, #131316), linear-gradient(45deg, #801AB2 0%, #4C4CFF 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    
    display: grid; 
    place-items: center;
}

.ingredient_image {
    margin-left: -32px;
    height: 64px;
}

.more {
    background-color: rgba(19, 19, 22, 0.5);
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    composes: text text_type_digits-default from global;
}
