.field {
    composes: mb-6 from global;
}

.buttons {
    display: flex;
    flex-direction: row-reverse;
}

.error_pane {
    display: flex;
    justify-content: center;
}

.error {
    composes: text text_type_main-default mt-6 from global;
    color: red;
}

.cancel {
    composes: mr-5 from global;
}
